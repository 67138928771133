export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "resourceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources List"])},
        "confirmToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you confirm to delete the resource package ?"])},
        "loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loaded"])},
        "not_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not loaded"])}
      },
      "zh": {
        "resourceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源包列表"])},
        "confirmToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否确认要删除该资源包"])},
        "loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功加载"])},
        "not_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未加载"])}
      }
    }
  })
}
