export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Load resource package ", _interpolate(_named("name")), "."])},
        "selectSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a source"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Resource Package"])}
      },
      "zh": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["加载资源包 ", _interpolate(_named("name"))])},
        "selectSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个源"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载资源包"])}
      }
    }
  })
}
